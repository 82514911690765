html {
    font-size: 100%;
    box-sizing: border-box;
}

*, *::before, *::after {
    box-sizing: inherit;
}

body {
    margin: 0;
    padding: 0;
    font-family: var(--font-opensans);
    background: var(--background);
    color: var(--text-color);
}

.section.section-center {
    display:flex; 
    justify-content:center;
    align-items:center;
    height:70vh;

    &.centerimage {
        &.dark {
            background-image: url("images/logo.png");
        }

        &.light {
            background-image: url("images/logo_day.png");
        }
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center;
    }
}

#etsy {
    &.dark {
        background-image: url("images/etsy_logo.svg");
    }

    &.light {
        background-image: url("images/etsy_logo_light.svg");
    }

    float: right;
    width: 60px;
    height: 60px;
    display: block;
}

.section.footer {
    position: absolute;
    bottom: 0px;
    right: 0px;
    text-align: right;
    padding-bottom: 10px;
    padding-right: 10px;
}

.visually-hidden {
    position: absolute;
    left: -10000px;
    top: auto;
    width: 1px;
    height: 1px;
    overflow: hidden;
}