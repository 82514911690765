@use '../util' as *;

h1, h2, h3 {
  margin-top: 0;
  line-height: 1.1;
}

h1 {
  font-size: rem(24);
  margin-bottom: rem(3);
  @include breakpoint(large) {
    font-size: rem(28);
  }
}

h2 {
  font-size: rem(24);
  margin-bottom: rem(24);
  color: var(--text-color2);
}

a, a:visited, a:active {
  text-decoration: none;
}

.section.section-center.maintext {
  font-size: 5vw;
}

.section.footer.logo {
  font-size: 28pt;
  opacity: 0.75;
  letter-spacing: -0.08em;
}

#centerimagetext {
  font-size: 11vh;
  letter-spacing:-0.07em;
  color: transparent;

  &.dark {
    -webkit-text-stroke: 0.15vh white;
  }

  &.light {
    -webkit-text-stroke: 0.15vh hsl(180, 58%, 93%);;
  }

}